@import '../../shared/colors';

.membership-type {
	align-items: center;
	background: white;
	border: 1px solid #eee;
	box-shadow: 0 3px 6px $grey-shadow;
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
	padding: 10px 15px;

	p {
		margin: 0;
	}

	h4 {
		margin: 0;
		width: 100%;
	}

	.price {
		color: #07333B;
		display: inline;
		font-size: 20px;
		font-weight: 700;
	}

	> span {
		display: block;
		font-size: 14px;
		opacity: 0.7;
		margin-left: 20px;
		flex-shrink: 0;
	}

	span {
		display: block;
	}
}

.family-members {
	margin: 20px 0;
}

.family-members fieldset {
	border: none;
	margin: 15px 0 5px;
	padding: 0;

	legend {
		margin-bottom: 0.5rem;
	}
}
