@import '../../../shared/colors';

.generic-notice {
  background-color: white;
  border: $red 2px solid;
  padding: 16px;
  margin-bottom: 1rem;

  .notice-header {
    display: flex;
    column-count: 2;
    margin-bottom: 1rem;

    .icon {
      width: 10%;
    }

    .title {
      width: 90%;
      font-weight: 600;
      text-align: left;
    }
  }
}