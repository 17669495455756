@import '../../../../shared/colors';

.dashcard {
	background-color: white;
	box-shadow: 0 0 6px $grey-shadow;
	padding-top: 16px;
	text-align: center;

	h2 {
		display: block;
		text-transform: uppercase;
		width: 100%;
	}
}