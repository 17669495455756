@import '../../shared/colors';

.view.login {
	.input-list {
		margin: 20px 0 0;
	}

	.disclaimer {
		font-size: 14px;
	}

	.login-wrapper {
		margin-bottom: 20px;
	}

	input[readonly] {
		background: $grey-medium;
	}
}

.disabled {
	opacity: 0.4;
	pointer-events: none;
	cursor: default !important;
}

.error {
	color: #D60929;
	font-weight: 600;
	margin: 20px 0;
}

.view.login h2 {
	font-size: 1.5rem;
	padding: 24px 0;
}

legend.MuiFormLabel-root {
	color: $dark;
	font-weight: 600;
	margin-bottom: 8px;
}

.description {
	color: $grey-dark;
	display: block;
	margin-top: 0;
	width: 100%;
}

label .link-style,
label a {
	color: $red;
	text-decoration: underline;
}


button {
	cursor: pointer;
}

button:focus {
	outline: none;
}
