.view.login {
	.input-list {
		margin: 20px 0 0;
	}

	.disclaimer {
		font-size: 14px;
	}

	.login-wrapper {
		margin-bottom: 20px;
	}
}
