$red: #D60929;

.member-card {
	background: #9a0a20;
	background-image: url(../../assets/card-top.png);
	background-position: top left;
	background-repeat: no-repeat;
	background-size: 100% auto;
	border-radius: 6px;
	box-shadow: 0 6px 8px rgba(0,0,0, 0.4);
	color: white;
	margin: 0 auto 20px;
	max-width: 400px;
	overflow: hidden;
	padding: 20px;
	position: relative;
	will-change: transform;

	.card-glare {
		background: linear-gradient(90deg, rgba(#fff, 0), rgba(#fff, 0.14), rgba(#fff, 0));
		height: 120%;
		left: 10%;
		position: absolute;
		top: 0;
		width: 80%;
	}

	.card-header {
		padding-bottom: 10px;
		text-align: center;

		.stamp {
			background-image: url(../../assets/logo-2.svg);
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			height: 82px;
			margin: 0 auto;
			width: 60px;
		}

		h2 {
			font-size: 41px;
			letter-spacing: 0.07em;
			margin: 5px 0;
			text-transform: uppercase;
		}
	}

	.badge {
		background: $red;
		border-radius: 20px;
		color: white;
		display: inline-block;
		font-size: 12px;
		letter-spacing: 0.07em;
		padding: 5px 15px;
		text-transform: uppercase;
	}

	.card-info {
		h3 {
			margin: 20px 0 0;
			opacity: 0.56;
		}

		span {
			font-family: "Knockout 48 A", "Knockout 48 B", sans-serif;
			font-size: 29px;
			letter-spacing: 0.07em;
		}
	}

	.card-footer {
		background-image: url(../../assets/stamp.svg);
		background-position: bottom right;
		background-repeat: no-repeat;
		height: 70px;
		padding-top: 10px;
	}
}

.card-desc {
	text-align: center;
}

.prevent-overflow {
	margin: 0 -15px;
	overflow: hidden;
	padding: 20px 15px;
}
