@import '../../shared/colors';

.menu-wrapper {
	position: fixed;
	top: 62px;
	left: 0;
	background: rgba( 0, 0, 0, 0.4 );
	width: 100vw;
	height: 100vh;
	z-index: 100;
	padding-bottom: 48px;
	overflow-y: auto;

	ul {
		display: block;
		width: 100%;
		background-color: white;
		margin-top: 0;
		padding-top: 0;

		li {
			list-style: none;
			border-top: $grey-light 2px solid;
			padding: 16px 0;
			margin: 0;

			a {
				display: block;
				width: 100%;
				height: 100%;

				&.important {
					color: $red;
					font-weight: 600;
				}
			}
		}
	}
}
