$red: #D60929;

.data-list {
	list-style: none;
	padding: 0;
	margin: 15px 0;

	span {
		font-size: 18px;
		margin-right: 7px;
	}

	li {
		margin-bottom: 1.5rem;

		.label {
			margin-bottom: 0.5rem;
		}
	}
}

.input-list {
	li {
		/* The use of `!important` here is to override MaterialUI which takes priority otherwise. */
		margin-bottom: 2rem!important;

		&.has-description {
			flex-direction: column;
		}

		.description {
			margin: 0 5px;
			font-size: 0.9rem;
		}
	}
}
