@import "../../shared/colors";

.loading-wrapper {
  display: block;
  text-align: center;
  padding: 2rem 0;
}
.loading-wrapper .app-loading-logo {
  background: url('../../assets/logo-color.svg') no-repeat center;
  content: "";
  height: 100px;
  display: block;
}
.loading-wrapper.pulse {
  animation-duration: 3s;
  animation-name: logopulse;
  animation-iteration-count: infinite;
}
@keyframes logopulse {
  from { opacity: 1; }
  50% { opacity: 0.5; }
  to { opacity: 1; }
}

.loading-long-wait {
  text-align: center;
  animation: fadeIn 1s;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}