.payment-wrap {
	margin: 40px 0;
	.payment {
		margin: 0 0 20px;
		background: white;
		padding: 15px 10px;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0 0 20px;
		font-size: 16px;

		.total {
			font-weight: 700;
		}
	}
}

.send {
	.wp-block-button__link {
		background: #D60929;
		color: white;
	}
}
