@import '../../shared/colors';

.view.login {
	h1,
	h2 {
		font-size: 52px;
		margin: 0;
		text-transform: uppercase;
	}

	.input-list {
		margin: 20px 0 0;

		label {
			display: inline-block;
			font-weight: 600;
			margin-bottom: 8px;
			width: 100%;
		}

		.input-group {
			display: grid;
			grid-column-gap: 16px;
			grid-template-columns: auto auto;
			width: 100%;

			button,
			.button {
				margin-bottom: 0;
				margin-top: 0;
			}
		}
	}

	input {
		background: white;
	}

	.disclaimer {
		font-size: 14px;
	}

	.login-wrapper {
		margin-bottom: 20px;
	}

	.login-response {
		font-weight: 600;
		margin-bottom: 20px;
		color: #D60929;
		animation: shake 400ms cubic-bezier(.36,.07,.19,.97) both;
	}

	.focus-block {
		background: white;
		border: 2px solid $grey-medium;
		margin-top: 1rem;
		padding: 1rem;

		a {
			font-weight: 600;
			text-decoration: underline;
		}
	}
}

@keyframes shake {
	0% { -webkit-transform: translate(3px); }
	20% { -webkit-transform: translate(-3px); }
	40% { -webkit-transform: translate(1.5px); }
	60% { -webkit-transform: translate(-1.5px); }
	80% { -webkit-transform: translate(0.8px); }
	100% { -webkit-transform: translate(0px); }
}
