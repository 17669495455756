$red: #D60929;
.map {
  height: 300px;
  margin: 20px 0;
  width: 100%;

  .marker {
    background: $red;
    border-radius: 100%;
    height: 15px;
    width: 15px;
  }
}

.geo-error {
	text-align: center;
  margin: 100px auto 20px;
  max-width: 400px;

  & p {
    font-size: 20px;
    margin-bottom: 40px;
  }
}
