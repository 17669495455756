@import url('https://cloud.typography.com/6243912/7957812/css/fonts.css');
@import './material.scss';
@import './shared/colors';

* {
  box-sizing: border-box;
}

button.MuiButton-contained {
	background: white;
}

body {
  background: $grey-light;
  font-family: "Verlag A", "Verlag B", sans-serif;
  margin: 0;
}

a {
  color: black;
  text-decoration: none;
}

.box {
  background: white;
  margin: 0 -15px 15px;
  box-shadow: 0 1px 2px rgba(black, .16);
  padding: 15px;
}

.notice {
  background-color: white;
  border: 2px solid $grey-medium;
  padding: 15px;
  position: relative;

  &.success {
    color: $green-darker;
    padding-right: 40px;

    &:before {
      background-image: url('assets/save.svg');
      background-repeat: no-repeat;
      content: "";
      height: 20px;
      display: inline-block;
      position: absolute;
      right: 14px;
      top: 18px;
      width: 20px;
    }
  }
}

.view {
  max-width: 400px;
  margin: 0 auto;
}

.view > .box:first-child {
  margin-top: -15px;
}

.view-controllers {
  background: black;
  margin: 0 -15px -30px;
  padding: 15px 45px;
  display: flex;

  button {
    color: white;
    background: black;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.05em;
  }

  button.important {
    background: $red;
  }
}

h1,
h2,
h3 {
  font-family: "Knockout 48 A", "Knockout 48 B", sans-serif;
  font-weight: 200;
}

h1 {
  font-size: 52px;
  margin: 0;
  text-transform: uppercase;
}

h2,
h3 {
  &.label {
    font-family: "Verlag A", "Verlag B", sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.07em;
    text-transform: uppercase;
  }
}

.input-list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin: 0 -5px 15px;
    display: flex;

    > div {
      width: 100%;
      margin: 0 5px;
    }
  }
}

p {
  font-size: 16px;

  &.disclaimer {
    font-size: 12px;
    opacity: 0.8;
  }

  a {
    color: $red;
  }
}

ul {
  font-size: 14px;
  padding: 0 15px;

  li {
    margin: 0 0 5px;
  }
}

.app {
  padding: 62px 15px 15px;
}
.app.modal-visible {
  padding-top: 15px;
}

.view {
  padding: 15px 0;

  h2:first-child {
    margin-top: 0;
  }
}

.block-link-wrapper {
  margin: 15px auto 30px;
}

.block-link {
  background: white;
  background-image: url(./assets/arrow.svg);
  background-position: center right 15px;
  background-repeat: no-repeat;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(black, .14);
  display: block;
  font-weight: 700;
  margin: 5px 0;
  padding: 15px 40px 15px 15px;
  text-align: left;
  font-family: "Verlag A", "Verlag B", sans-serif;
  font-size: 18px;

  &:active {
    background-color: $grey-light;
  }

  &:focus {
    outline: 0;
  }

  &.important {
    background-color: $red;
    background-image: url(./assets/arrow-white.svg);
    color: white;
  }

  &.unimportant {
    background-color: $dark;
    background-image: url(./assets/arrow-white.svg);
    color: white;
  }

  &.disabled {
    opacity: 0.2;
    background-color: #d4d4d4;
    color: black;
    background-image: url(./assets/arrow.svg);
  }

  &.plain {
    background-image: none;
    padding: 15px;
  }
}

.form-actions {
  margin-top: 32px;

  & .inline:not(:first-child){
    margin-left: 1rem;
  }
}

button,
a.button {
  border: 0;
  border-radius: 8px;
  font-weight: 700;
  margin: 5px auto;
  padding: 15px;
  width: 100%;
  display: inline-block;

  &.primary {
    background: $red;
    color: white;
  }

  &.secondary {
    background: white;
    color: black;
  }

  &.green {
    background: $green-darker;
  }

  &.subtle {
    background: transparent;
    margin: 15px auto;
    padding: 0;
  }

  &.inline {
    width: initial;
  }
}

.tow-wrapper {
  margin: 0 auto;
  max-width: 400px;
  text-align: center;

  h2.cords {
    margin-top: 0;
  }
}

.header {
  align-items: center;
  background: white;
  box-shadow: 0 3px 6px $grey-shadow;
  color: $dark;
  display: flex;
  height: 62px;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 90;

  h1 {
    font-family: "Verlag A", "Verlag B", sans-serif;
    font-size: 16px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
  }

  a,
  button {
    background: transparent;
    border-radius: 0;
    height: 100%;
    left: 0;
    margin: 0;
    padding: 15px;
    position: absolute;
    top: 0;
    width: auto;

    &:focus {
      outline: 0;
    }

    &.back {
      background-image: url(./assets/back.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 19px auto;
      width: 50px;
    }

    &.menu {
      background-image: url(./assets/menu.svg);
      background-position: center left 15px;
      background-repeat: no-repeat;
      background-size: 24px auto;
      color: white;
      font-size: 11px;
      font-weight: 500;
      letter-spacing: 0.07em;
      padding-left: 50px;
      text-transform: uppercase;
    }

    &.home {
      color: white;
      background-image: url(./assets/back.svg);
      background-position: center left;
      background-repeat: no-repeat;
      background-size: 12px auto;
      padding: 0 0 0 22px;
      margin-left: 10px;
      background-color: transparent;
      font-size: 14px;
      line-height: 62px;
    }

    &.toggle-menu {
      color: $grey-dark;
      background-image: url(./assets/menu-toggle.svg);
      background-position: center left 20px;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      padding-right: 38px;
      font-size: 14px;
      right: 0;
      left: initial;

      &.active {
        background-image: url(./assets/close.svg);
      }
    }
  }
}

button,
a {
  &.to-kna {
    color: $grey-dark;
    background-image: url(./assets/prev.svg);
    background-position: center left 10px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    padding-left: 35px;
    font-size: 14px;
  }
}



.logo {
  background-image: url(./assets/logo-color.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 45px;
  width: 35px;
}

p.very-large {
  font-size: 26px;
}

.install-propmt-overlay {
  background: rgba(0,0,0,0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  animation: checkoutin 200ms both;

  & .app-icon {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 4px 4px 10px rgba(0,0,0,0.5);
    display: inline-block;
    background-image: url(./icon-96x96.png);
    background-size: cover;
    background-position: center;
  }

  & h2 {
    margin-top: 10px;
  }

  & .modal-inner {
    border-radius: 8px;
    padding: 20px;
    background: white;
    width: 100%;
    text-align: center;
    position: relative;
    max-width: 450px;

    & button {
      background: $red;
      color: white;
      width: auto;
      padding: 10px 20px;

      &.simple {
        background: transparent;
        padding: 0;
        border-radius: 0;
        color: black;
        text-decoration: underline;
        text-transform: uppercase;
        font-size: 12px;
      }

      &.close-prompt {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        padding: 0;
        background-color: #252525;
        margin: 0;
        background-image: url(./close.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 12px auto;
      }
    }
  }

  &.ios-only {
    color: #252525;
    h3 {
      font-family: "Verlag A", "Verlag B", sans-serif;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 0;
    }
    .step-instructions ol {
      text-align: left;
      margin: 10px 0;
      list-style: none;
      padding: 0;

      li {
        position: relative;
        padding-left: 30px;
        margin: 0 0 15px;

        &::before {
          content: "1.";
          position: absolute;
          left: 0;
          font-size: 30px;
        }

        &:nth-child(2) {
          &::before {
            content: "2.";
          }
        }

        &:nth-child(3) {
          &::before {
            content: "3.";
          }
        }
      }

      p {
        font-size: 14px;
        margin: 0;

        span {
          font-size: 0;
        }
      }

      .step-example {
        display: block;
        width: 100%;
        height: 47px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-bottom: 5px;
      }

      .step-example-1 {
        background-image:url(./assets/step1.png);
      }

      .step-example-2 {
        background-image:url(./assets/step2.png);
      }

      .step-example-3 {
        background-image:url(./assets/step3.png);
      }
    }
    .main-instruction {
      text-align: center;
      font-size: 18px;
    }
  }
}

@keyframes promptin {
	from {
  	  opacity: 0;
    }
    to {
  	  opacity: 1;
    }
}
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;

  &:focus {
    background-color: #eee;
    clip: auto !important;
    clip-path: none;
    color: #444;
    display: block;
    font-size: 1em;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
  }
}

.icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 24px;
  width: 24px;

  &.large {
    height: 48px;
    width: 48px;
  }

  &.x-large {
    height: 72px;
    width: 72px;
  }

  &.arrow-right {
    background-image: url('assets/next.svg');

    &.white {
      background-image: url('assets/next-white.svg');
    }
  }

  &.arrow-left {
    background-image: url('assets/prev.svg');
  }

  &.person {
    background-image: url('assets/icons8-user.svg');
  }

  &.car {
    background-image: url('assets/icons8-car_rental.svg');
  }

  &.card {
    background-image: url('assets/icons8-soccer_yellow_card.svg');
  }

  &.sos {
    background-image: url('assets/icons8-sos.svg');
  }

  &.creditcard {
    background-image: url('assets/icons8-credit_card.svg');
  }

  &.friends {
    background-image: url('assets/icons8-friends.svg');
  }

  &.support {
    background-image: url('assets/icons8-online_support.svg');
  }

  &.bell {
    background-image: url('assets/icons8-notification.svg');
  }
}