.modal-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  padding: 0 15px 15px;
}

.close-modal-button {
  position: fixed;
  top: 10px;
  right: 10px;
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin: 5px;
}