@import '../../shared/colors.scss';

footer {
  background-color: $dark;
  margin: 0 -15px -15px;
  padding: 2rem 15px;

  a.to-kna {
    background-position: center left -5px;
    color: white;
    margin-bottom: 24px;
    padding: 8px 0 8px 24px;
  }

  .footer-logo {
    background: url( '../../assets/footer-logo.svg' ) no-repeat;
    content: "";
    display: block;
    height: 55px;
    margin: 40px 0;
    width: 100%;
  }

  hr {
    border: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    height: 0;
  }

  ul {
    margin-top: 8px;
    padding: 0;

    li {
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      list-style: none;
      margin: 0;
      padding: 0;

      a {
        color: white;
        display: block;
        padding: 16px 0;

        &.important {
          color: $red;
        }
      }
    }
  }
}