.partners {
	margin: 40px 0 0;

	.logos {
		display: flex;
		align-items: center;
		justify-content: space-between;

		img {
			max-width: 100%;
			width: 30%;
			height: auto;
		}
	}
}
